<template>

    <span>{{ formattedNumber }} €</span>

</template>

<script>
    import V from 'voUtils/V.js'


    export default {
    	voVueComponent: 'format-price',
    	props: {
    		price: {
    			type: [Number, String],
    			required: true
    		}
    	},

    	computed: {
    		formattedNumber() {
    			// Versuche die Eingabe in eine Zahl umzuwandeln
    			const numericValue = parseFloat(this.price);

    			// Überprüfe, ob die Umwandlung erfolgreich war
    			if (isNaN(numericValue)) {
    				return "Ungültige Eingabe";
    			}

    			// Teile die Zahl durch 100 und formatiere sie
    			return (numericValue / 100).toFixed(2).replace('.', ',');
    		}

    	},

    };
</script>